import { Component, OnInit, WritableSignal, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserCourseService } from '../../services/user-courses.service';
import { take } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { ILoginResponse } from '@core/interfaces/ilogin-response';
import { IUserCourse } from '../../interfaces/iuser-course';
@Component({
  selector: 'prism-course-history',
  templateUrl: './course-history.component.html',
  styleUrl: './course-history.component.scss'
})
export class CourseHistoryComponent implements OnInit {
  private userCourseService = inject(UserCourseService);
  private authService = inject(AuthService);
  private router = inject(Router);
  userCourses?: IUserCourse[];
  currentUser: WritableSignal<ILoginResponse | null> = this.authService.currentUserInfo;

  ngOnInit(): void {
    this.userCourseService.getCourseHistory(Number(this.currentUser()?.userId))
      .pipe(take(1)).subscribe((res: IUserCourse[]) => {
        this.userCourses = res;
    })
  }


  viewCourse(courseId: number) {
    this.router.navigateByUrl(`course/${courseId}`);
  }
}