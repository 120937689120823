import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'prism-resources',
  templateUrl: './resources.component.html',
  styleUrl: './resources.component.scss'
})
export class ResourcesComponent {
  private router = inject(Router);

  goToResourcesForm(): void {
    this.router.navigateByUrl('/resources/form');
  }
}
