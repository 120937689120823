import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InputType } from '@core/enums/input-type';
import { ResourceService } from '../../services/resource.service';
import { IResource } from '../../interfaces/iresource';
import { ToastService } from '@core/services/toast.service';
import { iif, take } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { IImpactVideo } from '../../interfaces/iimpact-video';
import { Resources } from '../../enums/resources';
import { UrlValidator } from '@core/validators/url.validator';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'prism-resources-form',
  templateUrl: './resources-form.component.html',
  styleUrl: './resources-form.component.scss'
})
export class ResourcesFormComponent {
  private fb = inject(FormBuilder);
  private resourceService = inject(ResourceService);
  private toastService = inject(ToastService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private urlValidator = inject(UrlValidator);
  private modal= inject(NzModalService);

  resourceForm: FormGroup | any;
  InputType = InputType;
  selectedFile: any;
  resource: any = {};
  formData: FormData | null = null;
  isEdit: boolean = false;
  formBtnLabel: string = 'Add Resource';
  resourceId: number = 0;
  resourceType: string;
  isExternal: boolean = false;
  isImpactVideo: boolean = false;
  formType: string = 'Add';

  get description() { return this.resourceForm.get('description'); }
  get buttonLink() { return this.resourceForm.get('buttonLink'); }
  get videoLink() { return this.resourceForm.get('videoLink'); }

  constructor() {
    this.route.url.subscribe(urlSegments => {
      const path = urlSegments[1].path; 
      this.isExternal = path === Resources.External;
      this.isImpactVideo = path === Resources.ImpactVideo;
    });
    this.resourceId = Number(this.route.snapshot.paramMap.get("id"));
    this.resourceType = this.isImpactVideo ? 'impact video' : 'external resource';
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.resourceForm = this.fb.group({
      id: [null],
      title: [null, Validators.required],
      description: [null, Validators.required],
      imageFileName: [null, Validators.required],
      buttonLabel: [null, this.isExternal ? Validators.required : null],
      buttonLink: [null, this.isExternal ? [Validators.required, this.urlValidator.isUrlValid()] : null],
      videoLink: [null, this.isImpactVideo ? [Validators.required, this.urlValidator.isUrlValid()] : null]
    });

    if (this.resourceId) {
      this.formType = 'Edit';
      iif (
        () => this.isExternal,
        this.resourceService.getResource(this.resourceId),
        this.resourceService.getImpactVideo(this.resourceId)
      ).pipe(take(1)).subscribe((res: IResource | IImpactVideo) => {
        if (res) {
          this.isEdit = true;
          this.resource = res;
          this.resourceId = res.id;
          this.formBtnLabel = `Update ${this.resourceType}`;
          this.resourceForm.patchValue({...res});
        }
      })
    }
  }

  public fileChange(event: any) {
		const fileList: FileList = event.file.target.files;
		if (fileList.length > 0) {
			const chosenFile: File = fileList[0];
			this.selectedFile = chosenFile;
			this.resourceForm.get('imageFileName').markAsDirty();
			this.resourceForm.patchValue({
				imageFileName: chosenFile.name,
			});
		}
	}

  onTextChange(event: any) {
    const emptyTagsRegex = /^((<[^>]+>)+\s*)+$/;
    if (!event.html) {
      this.description.setErrors({ 'required': true });
      this.description.updateValueAndValidity();
    } else if (emptyTagsRegex.test(event.html)) {
      this.description.setErrors({ 'emptyTags': true });
    } else if (this.description.valid) {
      this.description.value = event.html;
    }
  }

  submitResource(): void {
    if (this.resourceForm.invalid) {
      this.toastService.error('Please fill out all required fields');
      return;
    }

    if (this.isEdit && this.resourceForm.pristine) {
      this.toastService.error('No changes made');
      return;
    }

    if (this.resourceForm.value.id == null) this.resourceForm.get('id').setValue(0);

    Object.assign(this.resource, this.resourceForm.value);
    this.formData = new FormData();

    if (this.selectedFile) {
			this.formData.append('imageFile', this.selectedFile, this.selectedFile.name);
		}

    this.formData.append('resource', JSON.stringify(this.resource));

    iif (
      () => this.isExternal,
      this.resourceService.addResource(this.formData),
      this.resourceService.addImpactVideo(this.formData)
    ).pipe(take(1)).subscribe((success: boolean) => {
      if (success) {
        let path = this.isExternal ? 'external' : 'impact-video';
        if (this.isEdit) {
          this.toastService.success('Resource updated successfully');
          this.router.navigateByUrl(`resources/${path}/view/${this.resourceId}`);
        } else {
          this.toastService.success('Resource added successfully');
          this.resourceForm.reset();
          this.router.navigateByUrl(`resources/${path}`);
        }
      }
    })
  }

  deleteResource() {
    this.modal.confirm({
      nzTitle: `Delete ${this.resourceType}`,
      nzContent: `Are you sure you want to delete this ${this.resourceType}?`,
      nzOkText: 'Yes',
      nzIconType: "exclamation-circle",
      nzOnOk: () => {
        iif (
          () => this.isExternal,
          this.resourceService.deleteResource(this.resourceId),
          this.resourceService.deleteImpactVideo(this.resourceId)
        ).pipe(take(1)).subscribe((success: boolean) => {
          if (success) {
            this.toastService.success('Resource deleted successfully');
            let path = this.isExternal ? 'external' : 'impact-video';
            this.router.navigateByUrl(`resources/${path}`);
          }
        })
      },
      nzCancelText: 'No',
    });
  }
}
