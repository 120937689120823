import { Component, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { take, tap } from 'rxjs';
import { ICourses } from '@core/interfaces/ilookup';

@Component({
  selector: 'prism-course-user',
  templateUrl: './course-user.component.html',
  styleUrl: './course-user.component.scss'
})
export class CourseUserComponent {
  private lookupService = inject(LookupService);
  private authService = inject(AuthService);
  private sanitizer = inject(DomSanitizer);
  private router = inject(Router);
  private resourceContainer = 'courses';
  sasToken: string = '';
  courses: ICourses[] = [];

  ngOnInit(): void {
    this.getSasToken();
    this.getCourses();
  }

  getSasToken(): void {
    this.authService.getStorageSasToken(this.resourceContainer).pipe(
      take(1),
      tap((res: any) => {
        this.sasToken = res.value;
      })
    ).subscribe();
  }

  getCourses(): void {
    this.lookupService.getCourses().subscribe((res: any) => {
      this.courses = res;
    });
  }

  viewCourse(course: any): void {
    this.router.navigate([`/course/${course.id}`]);
  }
}
