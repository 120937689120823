import { Injectable } from '@angular/core';
import { CanActivateChild, Router, UrlTree } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { ToastService } from '@core/services/toast.service';
import { Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
  constructor(private auth: AuthService, private router: Router, private toast: ToastService) { }
  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let isValid = true;

    const isUserLoggedIn = this.auth.checkLogin();
    
    if (!isUserLoggedIn) {
      isValid = false;
      this.logoutCurrentUser();
      this.router.navigateByUrl('/');
      this.toast.error('Unauthorized access');
    }
    return isValid;
  }

  logoutCurrentUser(): void {
    const loggedInUser = this.auth.getCurrentUser();
    if (loggedInUser) this.auth.logout();
  }

}
