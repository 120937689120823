export enum UserHeaders {
  fullName = 'Name',
  emailName = 'Email',
  userTypeName = 'User Type',
  organizationName = 'Organization'
}

export enum UserProps {
  'First Name' = 'FirstName',
  'Last Name' = 'LastName',
  'Email' = 'Email'
}
