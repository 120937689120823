<div class="max-h-fit py-10">
  <prism-title title="Reports" additionalClasses="text-3xl pl-20 pb-4"></prism-title>
  <div class="grid grid-cols-3">
    <div class="col-span-3 xl:lg:col-span-1">
      <a (click)="viewDistrictReport()">
        <prism-report-card [isDistrict]="true"></prism-report-card>
      </a>
    </div>
    <div class="col-span-3 xl:lg:col-span-1">
      <a (click)="viewCompletedReport()">
        <prism-report-card [isCompleted]="true"
                           [data]="completedData"
                            title="Users Completed">
        </prism-report-card>
      </a>
    </div>
    <div class="col-span-3 xl:lg:col-span-1">
      <a (click)="viewInProgressReport()">
        <prism-report-card [isCompleted]="false"
                           [data]="inProgressData"
                           title="In Progress">
        </prism-report-card>
      </a>
    </div>
  </div>
</div>


