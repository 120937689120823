<div class="flex flex-row flex-wrap justify-center gap-4 mb-16">
  @if (isDistrict) {
    <div class="border border-light-gray p-1 py-10 w-[325px] h-40 text-center rounded-xl bg-light-purple shadow">
      <prism-title title="District Reports" additionalClasses="text-xl mb-0"></prism-title>
      <i class="uil uil-file-graph text-4xl text-dark-purple"></i>
    </div>
  } @else {
    <div class="border border-light-gray p-1 py-7 w-[325px] h-40 rounded-xl bg-light-purple shadow">
      <div class="grid grid-cols-3">
        <div class="col-span-2 pl-4">
          <prism-title [title]="title" additionalClasses="text-xl mb-0"></prism-title>
          <p class="text-4xl font-bold pt-1">{{ isCompleted ? data?.completedUsers : data?.inProgressUsers }}</p>
        </div>
        <div class="col-span-1 pr-3">
          <circle-progress
            [percent]="data?.progressPercent!"
            [radius]="100"
            [outerStrokeWidth]="16"
            [innerStrokeWidth]="8"
            outerStrokeColor="#6D3957"
            innerStrokeColor="#D7D7D7"
            [animation]="true"
            [animationDuration]="300"
            [responsive]="true"
            titleFontSize="60"
            titleFontWeight="bold"
            titleColor="#6D3957"
            unitsFontSize="50"
            unitsFontWeight="bold"
            unitsColor="#6D3957"
            [showSubtitle]="false"
          ></circle-progress>
        </div>
      </div> 
    </div>
  }
</div>
