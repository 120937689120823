<div class="mt-4 px-20">
  <prism-title title="Account Settings"></prism-title>
  <form [formGroup]="settingsForm" class="grid lg:grid-cols-2 gap-x-6 gap-y-4 mb-4" (submit)="saveChanges()">
    <prism-input
      [control]="settingsForm.get('firstName')"
      label="First Name"
      [required]="true"
      [maxLength]="50">
    </prism-input>
    <prism-input
      [control]="settingsForm.get('lastName')"
      label="Last Name"
      [required]="true"
      [maxLength]="50">
    </prism-input>
    <div class="col-span-1">
      <prism-select-organization
        [control]="settingsForm.get('districtId')"
        label="Organization"
        placeHolder="Select Organization"
        [readOnly]="!isSuperAdmin">
      </prism-select-organization>
    </div>
    <prism-input
      [control]="settingsForm.get('emailName')"
      label="Email Address"
      inputType="email"
      [readOnly]="!isEditable"
      [required]="true">
    </prism-input>
    <prism-select-grade
      [control]="settingsForm.get('gradeId')"
      label="Grade Level"
      placeHolder="Select Grade Level"
      [required]="!isEditable">
    </prism-select-grade>
    <prism-btn 
      class="flex items-end" 
      btnClass="btn-purple" 
      [disabled]="settingsForm.pristine || !settingsForm.valid">
      Submit
    </prism-btn>
  </form>
</div>
