import { Component, Input } from '@angular/core';
import { IReportCard } from '../interfaces/ireport-card';

@Component({
  selector: 'prism-report-card',
  templateUrl: './report-card.component.html',
  styleUrl: './report-card.component.scss'
})
export class ReportCardComponent {
  @Input() isDistrict: boolean = false;
  @Input() title: string = '';
  @Input() data?: IReportCard;
  @Input() isCompleted: boolean = false;
}
