<div class="px-20">
    <prism-title title="Courses History"></prism-title>
    @if (userCourses?.length == 0) {
      <p>No courses available.</p>
    }
    @else {
      <div class="grid md:grid-cols-4 gap-x-4 gap-y-12 mt-8 mb-16">
        <prism-course-card *ngFor="let course of userCourses"
          [course]="course"
          (click)="viewCourse(course.course.id)">
        </prism-course-card>
      </div>
    }
</div>