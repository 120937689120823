import { Injectable, inject } from '@angular/core';
import { DataService } from '@core/services/data.service';
import { Observable } from 'rxjs';
import { IReportCard, IReportCardRequest } from '../interfaces/ireport-card';
import { ISearchReportResponse } from '../interfaces/ireport-response';
import { IReportRequest } from '../interfaces/ireport-request';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  dataService = inject(DataService);

  getCardInfo(isCompleted: boolean, districtId?: number): Observable<IReportCard> {
    const payload: IReportCardRequest = { isCompleted, districtId };
    return this.dataService.post(`userCourse/card-info`, payload);
  }

  searchReport(payload: IReportRequest): Observable<ISearchReportResponse> {
    return this.dataService.post(`userCourse/search`, payload);
  }

  downloadReport(payload: IReportRequest): Observable<any> {
    return this.dataService.postWithOptions('userCourse/download', payload, {
      observe: 'response',
      responseType: 'blob',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  }
}
