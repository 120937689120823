import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

@Injectable()
export class UrlValidator {
  constructor() {}
  isUrlValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;  
      }

      try {
        new URL(control.value);
        return null;  
      } catch (err) {
        return { invalidUrl: true }; 
      }
    };
  }
}
