<div class="max-h-fit px-20 pt-10">
  <div class="grid grid-cols-2 py-5">
    <div class="col-span-2 lg:col-span-1 items-center pb-4">
      <h1 class="text-dark-purple text-3xl font-bold">{{ pageTitle }}</h1>
    </div>
    <div class="col-span-2 lg:col-span-1 pb-4 flex justify-end">
      <prism-btn 
              btnClass="bg-white text-dark-purple font-bold border-2 p-0 m-0 rounded-xl border-dark-purple"
              (click)="downloadReport()"
              [disabled]="!data.length"
          >
          <i class="uil uil-import text-dark-purple font-bold pr-1"></i
            >Download Report
      </prism-btn>
    </div>
    @if (user.roleId == role.SystemAdmin) {
      <div class="col-span-2 lg:col-span-1 items-center pb-4">
        <div class="w-full lg:w-3/4">
          <prism-input 
            inputType="text" 
            [control]="searchForm.get('search')"
            [required]="false"
            inputType="text"
            placeHolder="Search for name, course, organization, etc..."
            [hasSearch]="true"
            (search)="searchReport()">
          </prism-input>
        </div>
      </div>
      <div class="col-span-2 lg:col-span-1 pb-4 flex justify-end">
        <div class="w-full lg:w-1/2">
          <prism-select-organization
            [control]="searchForm.get('organization')"
            placeHolder="Select Organization"
            [required]="false">
          </prism-select-organization>
        </div>
      </div>
    }
  </div>
  <prism-table [columns]="columns" 
               [colHeaders]="colHeaders" 
               [tableData]="data" 
               [pageIndex]="pageIndex"
               [pageSize]="pageSize"
               [totalData]="totalData"
               (pageIndexChange)="handlePageIndexChange($event)"
               (pageSizeChange)="handlePageSizeChange($event)">
  </prism-table>
</div>