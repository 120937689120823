<div class="px-4 py-2">
  <div class="page-title">Bulk Add Users</div>
  <div class="grid grid-cols-2 gap-x-4 m-5">
    <div class="col-span-2 lg:col-span-1 items-center w-[100%] pb-4">
      <strong>Instructions</strong>
      <p>
        This page will allow administration to bulk upload their users into
        Prism.
      </p>
      <strong>File Requirements</strong>
      <div>
        <ul class="list-disc">
          <li>Must be in XLSX format.</li>
          <li>Must contain header row.</li>
          <li>
            Must be in specified file layout: firstName, lastName,
            emailName, districtId, and gradeId.
          </li>
          <li>
            Click
            <a
              [href]="generateUrlWithSasToken(fileLink)"
              target="_blank"
              class="link underline text-dark-purple"
              >here</a
            >
            to download the file layout.
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="mt-2">
    <div class="flex">
      <div class="file-btn-wrapper">
        <prism-btn
          btnType="button"
          btnClass="bg-white text-dark-purple font-bold border-2  p-2 rounded-xl border-dark-purple"
          (click)="fileInput.click()"
        >
          Choose File
        </prism-btn>
        <input
          #fileInput
          type="file"
          id="userUpload"
          class="form-control hidden"
          (change)="addFile($event)"
          accept=".csv, .xlsx, .xls"
        />
      </div>

      <div class="ml-4">
        @if (tableData && tableData.length && !canUpload) {
        <prism-btn
          btnType="button"
          btnClass="bg-dark-purple text-white font-bold border-2  p-2 rounded-xl border-white"
          (click)="validate()"
        >
          Validate
        </prism-btn>
        } @if (canUpload) {
        <prism-btn
          btnType="button"
          btnClass="bg-dark-purple text-white font-bold border-2  p-2 rounded-xl border-white"
          (click)="upload()"
        >
          Upload
        </prism-btn>
        }
      </div>
    </div>
  </div>

  <div class="results m-5">
    <table class="w-full table divide-y divide-gray-200">
      <thead>
        <tr>
          <th *ngFor="let header of headers" class="text-start">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of tableData; let i = index">
          <tr class="hover:bg-gray-200 cursor-pointer" (click)="edit(row)" [ngClass]="i % 2 === 0 ? 'even:bg-gray-100' : 'odd:bg-white'">
            <ng-container *ngFor="let col of row">
              <td class="pt-5">
                <span *ngIf="!row.edit" [ngClass]="{ 'invalid-data': col.invalid }" class="font-semibold">
                  {{ col.value }}
                  <span *ngIf="!col.displayValue || col.invalid" class="text-red">**</span>
                </span>
                <input *ngIf="row.edit" class="form-control pl-2 mt-2 p-1" maxlength="50" [ngClass]="{ 'text-red': col.invalid }" type="text" [(ngModel)]="col.value" />
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
</div>
</div>
