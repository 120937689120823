import { Component, WritableSignal, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ILoginResponse } from '@core/interfaces/ilogin-response';
import { ICourses } from '@core/interfaces/ilookup';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { IUserCourse } from '@views/features/courses/interfaces/iuser-course';
import { UserCourseService } from '@views/features/courses/services/user-courses.service';
import { take, tap } from 'rxjs';

@Component({
  selector: 'prism-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  private lookupService = inject(LookupService);
  private authService = inject(AuthService);
  private userCourseService = inject(UserCourseService);
  private router = inject(Router);
  private resourceContainer = 'courses';
  sasToken: string = '';
  courses: ICourses[] = [];
  userCourses?: IUserCourse[];
  currentUser: WritableSignal<ILoginResponse | null> = this.authService.currentUserInfo;

  ngOnInit(): void {
    this.getSasToken();
    this.getCourses();
    this.userCourseService.getCourseHistory(Number(this.currentUser()?.userId))
      .pipe(take(1)).subscribe((res: IUserCourse[]) => {
        this.userCourses = res;
    })
  }

  getSasToken(): void {
    this.authService.getStorageSasToken(this.resourceContainer).pipe(
      take(1),
      tap((res: any) => {
        this.sasToken = res.value;
      })
    ).subscribe();
  }

  getCourses(): void {
    this.lookupService.getCourses().subscribe((res: any) => {
      this.courses = res;
    });
  }

  viewCourse(courseId: any): void {
    this.router.navigate([`/course/${courseId}`]);
  }
}
