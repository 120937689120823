<div class="mt-4 px-20">
  <prism-title title="Courses"></prism-title>
  <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-12 mt-4 mb-16">
    @for (course of courses; track $index) {
      <prism-card [url]="course.thumbnail"
                  [author]="course.author"
                  [title]="course.name"
                  [entity]="course"
                  [sasToken]="sasToken"
                  (cardClick)="viewCourse($event)">
      </prism-card>
    }
  </div>
</div>
