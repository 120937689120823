<div class="pt-16 px-20">
  <nz-tabset nzType="card" class="rounded-xl w-full h-full">
    <nz-tab *ngFor="let tab of tabs; let i = index" [nzTitle]="tab">
      @if(i === 0) {
        <ng-container>
          <prism-user-form></prism-user-form>
        </ng-container>
      }
      @if(i === 1) {
        <ng-container>
          <prism-user-upload></prism-user-upload>
        </ng-container>
      }
    </nz-tab>
  </nz-tabset>
</div>

