export enum ReportHeaders {
  fullName = 'Name',
  courseName = 'Course Name',
  status = 'Status',
  organizationName = 'Organization Name',
  completedOn = 'Complete Date',
  grade = 'Grade',
}

export enum ReportProps {
  'Name' = 'fullName',
  'Course Name' = 'courseName',
  'Status' = 'status',
  'Complete Date' = 'completedOn',
  'Grade' = 'grade'
}
