import { Injectable, inject } from "@angular/core";
import { DataService } from "@core/services/data.service";
import { Observable } from "rxjs";
import { IUserCourse } from "../interfaces/iuser-course";

@Injectable({
  providedIn: 'root'
})
export class UserCourseService {
  dataService = inject(DataService);

  getCourseHistory(userId: number): Observable<IUserCourse[]> {
    return this.dataService.get(`userCourse/course-history/${userId}`);
  }

}
