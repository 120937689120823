import { Component, ViewEncapsulation, inject } from '@angular/core';
import { UserManagementService } from '../../services/user-management.service';
import { ToastService } from '@core/services/toast.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Role } from '@core/enums/roles';
import { IDistricts, IRoles } from '@core/interfaces/ilookup';
import { UserValidator } from '@core/validators/user.validator';
import { take, tap } from 'rxjs';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'prism-user-form',
  templateUrl: './user-form.component.html',
  styleUrl: './user-form.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class UserFormComponent {
  userManagementService = inject(UserManagementService);
  private toastService= inject(ToastService);
  private userValidator = inject(UserValidator);
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);
  user = this.authService.getCurrentUser();
  isDistrictAdmin = this.user.roleId == Role.DistrictAdmin;
  userForm!: FormGroup;
  roles = Role;
  roleOptions: IRoles[] = [];
  organizationOptions: IDistricts[] = [];
  initialEmail: string = '';

  get firstName() {
    return this.userForm.get('firstName');
  }
  get lastName() {
    return this.userForm.get('lastName');
  }
  get gradeId() {
    return this.userForm.get('gradeId');
  }
  get emailName() {
    return this.userForm.get('emailName');
  }
  get roleId() {
    return this.userForm.get('roleId');
  }
  get districtId() {
    return this.userForm.get('districtId');
  }

  ngOnInit(): void {
    this.initializeForm();
    this.listenUserType();
    this.listenOrganization();
  }

  initializeForm(): void {
    this.userForm = this.fb.group({
      id: [0],
      firstName: [null, [Validators.required, Validators.maxLength(50)]],
      lastName: [null, [Validators.required, Validators.maxLength(50)]],
      gradeId: [null],
      emailName: [null, Validators.required],
      roleId: [null, Validators.required],
      districtId: [this.isDistrictAdmin ? this.user.districtId : null, Validators.required],
    },
    { validator:
      [
        this.userValidator.validateEmailAddress('emailName', this.initialEmail)
      ]
    });
  }

  listenUserType(): void {
    this.roleId?.valueChanges.subscribe((roleId: number) => {
      if (roleId !== Role.SystemAdmin) {
        this.districtId?.setValidators([Validators.required]);
      } else {
        this.districtId?.clearValidators();
      }
      this.districtId?.updateValueAndValidity();
    });
  }


  listenOrganization(): void {
    this.districtId?.valueChanges.subscribe(() => {
      const districtIdControl = this.districtId;
      if (districtIdControl && districtIdControl.hasError('required')) {
          if (districtIdControl.value !== null && districtIdControl.value !== '') {
              districtIdControl.setErrors(null);
          }
      }
    });
  }

  saveUser(): void {
    if (this.userForm.invalid) {
      this.toastService.error('Please fill out all required fields');
      return;
    }
    const payload = {
      ...this.userForm.value
    }

    if (payload.id == null) payload.id = 0;

    this.userManagementService.addUser(payload).pipe(
      take(1),
      tap((res: boolean) => {
        if (res) {
          this.toastService.success('User added successfully');
          this.userForm.reset();
          if (this.isDistrictAdmin) this.districtId?.setValue(this.user.districtId);
        }
      })
    ).subscribe();
  }
}
