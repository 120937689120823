import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ReportService } from '../services/report.service';
import { IReportCard } from '../interfaces/ireport-card';
import { AuthService } from '@core/services/auth.service';
import { Role } from '@core/enums/roles';

@Component({
  selector: 'prism-report-cards',
  templateUrl: './report-cards.component.html',
  styleUrl: './report-cards.component.scss'
})
export class ReportCardsComponent {
  private router = inject(Router);
  private reportService = inject(ReportService);
  private authService = inject(AuthService);
  completedData?: IReportCard;
  inProgressData?: IReportCard;
  user = this.authService.getCurrentUser();
  districtId = this.user?.districtId;

  ngOnInit(): void {
    this.getCardInfo(true);
    this.getCardInfo(false);
  }

  getCardInfo(isCompleted: boolean): void {
    const { roleId } = this.user;
    this.reportService.getCardInfo(isCompleted, roleId == Role.DistrictAdmin ? this.districtId : null)
                      .subscribe((res: IReportCard) => {
      if (isCompleted) {
        this.completedData = res;
      } else {
        this.inProgressData = res;
      }
    });
  }

  viewDistrictReport(): void {
    this.router.navigate([`/report/report-view`], { queryParams: { type: 'district' } });
  }

  viewCompletedReport(): void {
    this.router.navigate([`/report/report-view`], { queryParams: { type: 'completed' } });
  }

  viewInProgressReport(): void {
    this.router.navigate([`/report/report-view`], { queryParams: { type: 'progress' } });
  }
}
